<template>
    <div class="page-container">
        <div class="page-top">
            <div class="pic-box">
                <img src="../../assets/productdetail/tmp-cover-pic4.png" alt="" class="nft-pic">
            </div>
            <div class="info-box">
                <div class="info-title-box">
                    <div class="info-title">发布会纪念版徽章</div>
                    <div class="btn-grp">
                        <div class="btn-fav">
                            <img src="../../assets/productdetail/icon-fav.png" alt="" class="icon-fav">
                            112
                        </div>
                        <img src="../../assets/productdetail/icon-share.png" alt="" class="icon-share">
                    </div>
                </div>
                <div class="fxl-buycount">
                    <img src="../../assets/productdetail/icon-fxl.png" alt="" class="icon-fxl">
                    <div class="fxl-count">300</div>
                    <div class="buy-count">已有 <span>56</span>人购买</div>
                </div>
                <div class="auth-info">
                    艺术家：北京信息产业协会元宇宙专委会（筹） | 作品集：中国元宇宙系列白皮书
                </div>
                <div class="price">¥ 200</div>
                <div class="btn-buy">立即购买</div>
                <div class="chain-info">
                    <div class="chain-head">链上信息</div>
                    <div class="chain-block">
                        <div class="chain-info-item">合约地址：0x84FC3ea417Eb7cB229C6ce95843f68dAC59C0088</div>
                        <div class="chain-info-item">合约类型：ERC-1155</div>
                        <div class="chain-info-item">区块链：文昌链</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-bottom">
            <!-- 作者信息 -->
            <div class="auth-block">
                <div class="pic-name">
                    <img src="../../assets/productdetail/icon-author2.png" alt="" class="auth-pic">
                    <div class="auth-name">北京信息产业协会元宇宙专家委员会（筹）</div>
                </div>
                <div class="auth-detail">北京信息产业协会元宇宙专家委员会（筹）致力于保证中国元宇宙行业的健康发展、引领中国元宇宙科技创新、促进中国元宇宙的技术进步、提高中国元宇宙的科普水平。</div>
            </div>
            <div class="page-title">
                <img src="../../assets/icon-artist-arrow.png" alt="" class="arrow-left">
                <div>藏品描述</div>
                <img src="../../assets/icon-artist-arrow.png" alt="" class="arrow-right">
            </div>
            <div class="desc-list">
                <div class="desc-item">
                    <div class="desc-item-head">藏品介绍</div>
                    <div class="desc-item-content">元宇宙子白皮书《中国元宇宙社会治理白皮书》尚未创制，即将开启共创模式，收藏该数藏者将获得该书参编权。可基于自身需求或经验，选择不同子白皮书主题并参与其中，为行业赋能，为元宇宙开疆拓土。</div>
                </div>
                <div class="desc-item">
                    <div class="desc-item-head">权益说明</div>
                    <div class="desc-item-content">数字藏品可以通俗理解为区块链凭证。数字藏品为虚拟数字商品，特性为不可分割、不可替代、独一无二。数字藏品常见于文化艺术品领域、知识产权的链上发行、流转、确权等作用，能有效保护链上知识产权，防止篡改、造假等，是区块链技术的一类场景应用。</div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" src="./index.scss" scoped>

</style>